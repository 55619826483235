<div [ngStyle]="{'display': isVisible ? '' : 'none'}">
    <app-menu-default-card
    [isSidebarCollapsed]="isSidebarCollapsed"
    [ngClass]="isDrawingClass()"
    >
        <div class="mb-4 mx-auto font-bold">
            {{ 'main-page.sidebar.map-tools.title' | translate }}
        </div>
        <div class="border border-gray-200 bg-gray-100 px-4 py-4 w-11/12 mr-4 flex flex-col gap-4">
            <div class="text-gray-700">
                {{ 'main-page.sidebar.map-tools.title-measure' | translate }}
            </div>
            <div class="flex gap-4 w-full">
                <app-button
                (click)="onClick('polygon')"
                [leftIcon]="faDrawPolygon"
                [label]="'main-page.sidebar.map-tools.area-tool' | translate"
                ></app-button>
                <app-button
                (click)="onClick('line')"
                [leftIcon]="faSlash"
                [label]="'main-page.sidebar.map-tools.distance-tool' | translate"
                ></app-button>
                <app-button
                *ngIf="hasResults && !isDrawing"
                size="lg"
                [noLabel]="true"
                [isDangerous]="true"
                [leftIcon]="faDeleteLeft"
                (click)="clearMap()"
                ></app-button>
            </div>
            <div *ngIf="hasResults">
                <span class="text-asset-100">{{ resultLabel | translate }}</span>
                <p>{{ resultValue | number: '1.2-2' }} {{resultUnits}}<sup *ngIf="hasPower">2</sup></p>
            </div>
            
        </div>
        <div class="border border-gray-200 bg-gray-100 px-4 py-4 w-11/12 mr-4 mt-2 flex flex-col gap-4">
            <div class="text-gray-700">
                {{ 'main-page.sidebar.map-tools.title-file-upload' | translate }}
            </div>
            <app-load-layer
            (outputLayer)="onFileUpload($event)"
            (removeLayer)="onRemoveLayer($event)"
            (zoomToLayer)="onZoomToLayer($event)"
            [uploadedLayers]="uploadedLayers"
        ></app-load-layer>
        </div>
        

    </app-menu-default-card>
</div>

<app-identify-dialog
  (cancel)="onIdentifyClose()"
>
</app-identify-dialog>
