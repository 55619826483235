<footer
		class="
    z-40
    font-sans
    grid
    grid-cols-4
    items-center
    sm:text-left
    py-4
    px-6
    bg-gray-100
    items-center
    text-sm
  "
>
	<div class="flex flex-wrap col-span-3 gap-2">
		<a href="/home">
			<svg-icon src="assets/img/deimos.svg" svgClass="h-9"></svg-icon>
		</a>
		<a>
			<svg-icon src="assets/img/ISEA_text.svg" svgClass="h-9"></svg-icon>
		</a>
		<img alt="" class="h-9" src="assets/img/BC.png">
		<img alt="" class="h-9" src="assets/img/terraspatium_teliko_WEB.jpg">
		<img alt="" class="h-9" src="assets/img/terrasigna-logo.jpg">
		<img alt="" class="h-9" src="assets/img/SCMF logo.png">
		<img alt="" class="h-9" src="assets/img/UH.png">
		<img alt="" class="h-9" src="assets/img/CESAM_UA.jpg">
		<img alt="" class="h-9" src="assets/img/HUA.png">
		<img alt="" class="h-9" src="assets/img/Kapitech.png">
		<img alt="" class="h-9" src="assets/img/harris-logo.png">
	</div>

  <div *ngIf="false" class="text-center">
    <div *ngIf="env.production else staging">
      <span>made with ☕️ at <a href="https://elecnor-deimos.com/" target="_blank">&copy; Deimos</a></span>
      <span> - v{{pkg.version}}</span>
    </div>
    <ng-template #staging>
      <a class="items-center justify-center flex gap-4 z-40 bg-danger-500 shadow-inner bottom-7 rounded-full py-1 px-2 hover:px-5"
         href="https://jira.elecnor-deimos.com/projects/SERV4EO?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=unreleased"
         target="_blank">
        <span class="text-gray-50">🛰 services4eo - v{{pkg["service4eo-version"]}}</span>
        <span class="text-gray-50">⚙️ application - v{{pkg["version"]}}</span>
      </a>
    </ng-template>
  </div>

  <div class="flex gap-4 text-gray-700 place-content-end">
    <p *ngIf="coordinates">
      <span class="text-asset font-bold">
        {{ "main-page.footer.coordinates" | translate }}:
      </span>
			<span>{{ coordinates }}</span>
		</p>
		<p *ngIf="scale">
      <span class="text-asset font-bold">
        {{ "main-page.footer.scale" | translate }}
      </span>
			<span> 1:{{ scale | number: "1.0-0" }}</span>
		</p>
	</div>
</footer>
