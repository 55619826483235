import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@core';
import {
  faQuestionCircle,
  faSignInAlt,
  faSignOutAlt,
  faTimes,
  faUser,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { environment } from '../../../../environments/environment';
import * as pkg from '../../../../../package.json';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class NavbarComponent implements OnInit {
  isEnglish: boolean = this.translateService.currentLanguage === 'en';
  userRole: string = 'none';

  @Input() isAuthenticated: boolean | null = false;
  @Output() currentLang = new EventEmitter<string>();
  @Output() login = new EventEmitter();
  @Output() logout = new EventEmitter();
  isShowingProfileCard: boolean = false;
  faUser = faUser;
  signOutIcon = faSignOutAlt;
  close = faTimes;
  signIn = faSignInAlt;
  questionIcon = faQuestionCircle;
  faInfoCircle = faInfoCircle;
  env = environment;
  pkg = pkg;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private translateService: TranslateService
  ) {}
  


  ngOnInit(): void {}

  setLang(lang: string): void {
    this.currentLang.emit(lang);
  }

  onLogin(): void {
    this.login.emit();
  }

  onLogout(): void {
    this.isShowingProfileCard = false;

    this.logout.emit();
  }

  getInitials(name: string): string {
    if (!name) {
      return 'U';
    }
    const initials = name.split(' ');
    if (initials.length >= 2) {
      return (
        initials[0].charAt(0).toUpperCase() +
        initials[1].charAt(0).toUpperCase()
      );
    } else {
      return initials[0].charAt(0).toUpperCase();
    }
  }

  languageClass(fontWeightBefore: string, fontWeightAfter: string) {
    return this.isEnglish ? fontWeightBefore : fontWeightAfter;
  }

  get currentLanguage(): string {
    return this.translateService.currentLanguage;
  }

  get tutorialPath(): string {
    switch (this.currentLanguage) {
      case 'en':
        return this.env.tutorial.en;
      case 'fr':
        return this.env.tutorial.fr;      
      default:
        return this.env.tutorial.en;
        break;
    }
  }
}
