import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService, TimelineService, TranslateService } from '@core';
import {
  faFileDownload,
  faSearchMinus,
  faSearchPlus,
  faChartLine,
  faCirclePlay,
  faStop,
  faSpinner,
  faTrashCan,
  faArrowPointer
} from '@fortawesome/free-solid-svg-icons';
import { SeriesChartComponent } from '../series-chart/series-chart.component';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import mime from 'mime';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, OnDestroy, AfterViewInit {
  faSearchMinus = faSearchMinus;
  faSearchPlus = faSearchPlus;
  faFileDownload = faFileDownload;
  faChartLine = faChartLine;
  faCirclePlay = faCirclePlay;
  faStop = faStop;
  faSpinner = faSpinner;
  faArrowPointer = faArrowPointer;
  faTrashCan = faTrashCan;

  @Input() id!: string;

  @Input() label?: string;

  @ViewChildren(SeriesChartComponent) chartList?: QueryList<SeriesChartComponent>;

  animationDateSelect = false;

  componentDestroyed$: Subject<boolean> = new Subject();

  isDownloadingFile: boolean = false;

  statusMap: {[x: string]: string} = {
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '404': 'Not Found',
    '500': 'Server Error'
  }

  constructor(
    private timelineService: TimelineService,
    private translateService: TranslateService,
    private snackbarService: SnackbarService) {}

  get currentUri() {
    return this.timelineService.currentUri;
  }

  get visualizationId() {
    return `visualization-${this.id}`
  }

  ngOnInit() {
    setTimeout(() => this.timelineService.initializeTimeLine(this.id), 50);
    this.translateService.currentObservableLanguage$
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((lang) => {
      this.timelineService.changeTimelineLocale(lang);
      this.timelineService.destroyTimeline(this.id);
      this.timelineService.initializeTimeLine(this.id);
    });
  }

  ngOnDestroy(): void {
    this.timelineService.currentTime = undefined;
    this.timelineService.closeTimeseriesPlot();
    this.componentDestroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.timelineService.seriesCharts = [];
    this.chartList?.forEach((chart) => {
      this.timelineService.seriesCharts.push(chart);
    });
  }

  zoomIn() {
    this.timelineService.zoomIn(this.id);
  }

  zoomOut() {
    this.timelineService.zoomOut(this.id);
  }

  noCurrentUriClass() {
    return { 'pointer-events-none text-gray-300': (!this.currentUri || this.isDownloadingFile) };
  }

  onPlotClicked() {
    this.timelineService.toggleTimeseriesPlot();
  }

  get isLayerActive(): boolean {
    return this.timelineService.currentTime !== undefined;
  }

  get isPlotDrawingActive() {
    return this.timelineService.isPlotDrawingActive;
  }

  onAnimationClicked() {
    this.animationDateSelect = true;
  }

  onTimeIntervalSelect([start, end, frameRate]: [Date, Date, number]) {
    const timeInterval = `${start.toISOString()}/${end.toISOString()}`;
    this.animationDateSelect = false;
    if (this.label) this.timelineService.animateLayer(this.label, timeInterval, frameRate);
  }

  onAnimationStopClicked() {
    if(this.label) this.timelineService.stopAnimatedLayer(this.label);
  }

  onCancelAnimation() {
    this.animationDateSelect = false;
  }

  get isAnimationActive() {
    return this.timelineService.isAnimationActive;
  }

  get isTimeSeries() {
    if (this.label) {
      return this.timelineService.isTimeSeries(this.label);
    } else {
      return false;
    }
  }

  get animationLoading() {
    return this.timelineService.isAnimationLoading;
  }

  updateSeriesCharts() {
    this.timelineService.seriesCharts = [];
    this.chartList?.forEach((chart) => {
      this.timelineService.seriesCharts.push(chart);
    });
  }

  onSerieschartDateChange(event: any) {
    this.timelineService.serieschartDateChange(event);
  }

  onSerieschartClose() {
    this.timelineService.serieschartClose();
  }

  toggleMultipleSelection() {
    this.timelineService.toggleSelectionMode(this.id);
  }

  get isMultiSelectOn() {
    return this.timelineService.activeSelectionMode === 'multi';
  }

  get numSelected() {
    return this.timelineService.numSelectedTimes;
  }

  get timelineLabel() {
    const layer = this.timelineService.layersSelected?.find(l => this.label && l.orderID === parseInt(this.label.split('!')[1]));
    return [this.label?.split('!')[0], layer?.simpleOrderId].join('_');
  }

  downloadOutput(url?: string) {
    if (url) {
      this.isDownloadingFile = true;
      this.snackbarService.info(
        this.translateService.translate('main-page.orders.order-details.snackbar.downloadTitle'), 
        this.translateService.translate('main-page.orders.order-details.snackbar.downloadMessage'))
      .during(5000).show();
      this.timelineService.downloadFile(url)
      .pipe(take(1)).subscribe({
        next: (result) => {
          this.isDownloadingFile = false
          const extension = mime.getExtension(result.type);
          saveAs(result, `${this.timelineService.currentTitle}.${extension}`);
        },
        error: (err: HttpErrorResponse) => {
          this.isDownloadingFile = false;
          if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === "application/json") {
            let reader = new FileReader();

            reader.onload = (e: Event) => {
              const errmsg = JSON.parse((<any>e.target).result);
              this.snackbarService.danger(this.statusMap[err.status] ?? err.statusText, errmsg).during(5000).show();
            }

            reader.readAsText(err.error);
          } else {
            this.snackbarService.danger(err.statusText, err.message).during(5000).show();
          }
        }
      });
    }
  }





}
