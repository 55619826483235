import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Layer, MapService, TranslateService } from '@core';
import {
  faDrawPolygon,
  faSlash,
  faEraser
} from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-map-tools',
  templateUrl: './map-tools.component.html',
  styleUrls: ['./map-tools.component.scss']
})
export class MapToolsComponent implements OnInit, OnDestroy {

  @Input() isSidebarCollapsed: boolean = false;
  @Input() isVisible: boolean = true;

  faDrawPolygon = faDrawPolygon;
  faSlash = faSlash;
  faDeleteLeft = faEraser;

  drawingType: string;
  resultLabel: string;
  resultValue: number | undefined;
  resultUnits: string;

  componentDestroyed$: Subject<boolean> = new Subject();

  constructor(private mapService: MapService, private translateService: TranslateService) {
    this.drawingType = '';
    this.resultLabel = '';
    this.resultValue = undefined;
    this.resultUnits = '';
    this.mapService.drawingLayer$.pipe(
      takeUntil(this.componentDestroyed$))
      .subscribe(() => {
        if (this.mapService.drawingContext === 'measurement') {
          if (this.drawingType === 'line') {
            this.resultLabel = 'main-page.sidebar.map-tools.distance-label';
            this.resultValue = this.mapService.calculateLineDistance();
            this.resultUnits = 'm'
          } else if (this.drawingType === 'polygon') {
            this.resultLabel = 'main-page.sidebar.map-tools.area-label';
            this.resultValue = this.mapService.calculateArea();
            this.resultUnits = 'm'
          }
        }
      });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clearMap();
    this.mapService.destroyDrawingLayer();
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.mapService.removeIdentifyMarker();
  }

  get isDrawing() {
    return this.mapService.isDrawing;
  }
  
  isDrawingClass() {
    return {
      hidden: this.isDrawing
    };
  }

  onClick(type: string) {
    this.drawingType = type;
    this.mapService.drawOnMap(type, 'measurement');
  }

  clearMap() {
    this.mapService.clearMap();
    this.resultValue = undefined;
  }

  get hasPower() {
    return this.isPolygon
  }

  get hasResults() {
    return this.resultValue;
  }

  get isPolygon() {
    return this.drawingType === 'polygon';
  }

  get isLine() {
    return this.drawingType === 'line'
  }

  onIdentifyClose() {
    this.mapService.removeIdentifyMarker();
  }

  onFileUpload([layer, leafletSource]: [Layer, any]) {
    if (leafletSource) this.mapService.addExistingLayerToMap(layer.layerKey, layer, leafletSource)
    else this.mapService.addGeoTiff(layer.layerKey, layer);
    this.mapService.addUploadedLayer(layer);
  }

  get uploadedLayers() {
    return this.mapService.uploadedLayersList;
  }

  onRemoveLayer(layer: Layer) {
    this.mapService.removeUploadedLayer(layer);
  }

  onZoomToLayer(layer: Layer) {
    if (layer.wmsParams.bbox && layer.wmsParams.bbox !== '') this.mapService.zoomToBoundingBox(layer.wmsParams.bbox);

  }

}
