import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import localePt from '@angular/common/locales/pt-PT';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  AuthConfigModule,
  AuthInterceptor,
  CoreModule,
  LocalStorageManagerService
} from '@core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppRoutingModule } from 'app-routing.module';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppComponent } from './app.component';

registerLocaleData(localePt, 'fr');

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
    AuthConfigModule,
    AuthModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    AngularSvgIconModule.forRoot(),
    LoggerModule.forRoot({
      serverLoggingUrl: '/logs',
      level: NgxLoggerLevel.TRACE,
      serverLogLevel: NgxLoggerLevel.ERROR,
      enableSourceMaps: true,
      timestampFormat: 'full'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: AbstractSecurityStorage,
      useClass: LocalStorageManagerService
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
