<nav
		class="
    flex
    justify-between
    font-sans
    text-center
    bg-gray-50
  "
>
	<div class="px-4 py-2">
		
		<a class="flex gap-2" href="/">
			<a href="http://spaceforshore.eu/" target="_blank">
				<img alt="space 4 shore logo" class="h-12"
				src="assets/img/logo.svg"/>
			</a>
			<a href="https://i-sea.fr/en/" target="_blank">
				<img alt="ISEA logo" class="h-12" src="assets/img/ISEA.svg"/>
			</a>
			
		</a>
	</div>

  <a *ngIf="!env.production"
      class="absolute z-50 bg-danger-500 top-0 left-48 px-4 rounded-b-full shadow-md hover:px-5 active:scale-x-95 duration-300"
      href="https://jira.elecnor-deimos.com/projects/SERV4EO?selectedItem=com.atlassian.jira.jira-projects-plugin%3Arelease-page&status=unreleased"
      target="_blank">
    <span class=" text-sm text-gray-50">v{{pkg.version}}</span>
  </a>
  <div class="flex gap-4 justify-end py-4 px-4">
	<div class="flex h-full item-center justify-center">
		<a>
			<img alt="" class="h-9" src="assets/img/esa.svg">
		</a>
	</div>
	<div class="flex flex-row">
		<a [href]="tutorialPath" target="_blank" class="text-asset">
			<button
			class="rounded-md py-2 px-4 hover:bg-gray-50 hover:shadow"
			>
				<fa-icon [icon]="faInfoCircle" size="1x"></fa-icon>
				<span class="uppercase"> {{ 'main-page.navigation.tutorial' | translate}}</span>
			</button>
		</a>
		<a [href]="env.support" class="text-asset" target="_blank">
			<button
					class=" rounded-md py-2 px-4 hover:bg-gray-50 hover:shadow">
				<fa-icon [icon]="questionIcon"></fa-icon>
				<span class="uppercase">
					{{ 'main-page.navigation.support' | translate}}</span>
			</button>
		</a>
	</div>
    <app-lang-dropdown (selectedLanguage)="setLang($event)"></app-lang-dropdown>
    <div *ngIf="this.isAuthenticated; else signInButton">
      <button
        class="
          bg-gradient-to-r
          from-primary
          to-primary-600
          rounded-full
          h-9
          w-9
          transform
          duration-300
          ease-in-out
          motion-safe:hover:scale-105 motion-safe:active:scale-100
        "
					(click)="isShowingProfileCard = true"
					*ngIf="(oidcSecurityService.getUserData() | async) as user"
			>
				<h5 class="m-0 text-gray-50 font-bold text-1xl">
					{{ getInitials(user.displayName) }}
				</h5>
			</button>
		</div>
		<ng-template #signInButton>
			<app-button
					(click)="onLogin()"
					[label]="'auth.sign-in' | translate"
					[leftIcon]="signIn"
			></app-button>
		</ng-template>
	</div>
</nav>
<div
		(outsideClick)="isShowingProfileCard = !isShowingProfileCard"
		*ngIf="isShowingProfileCard"
		[@slideInOut]
>
	<div
			*ngIf="(oidcSecurityService.getUserData() | async) as user"
			class="
      z-50
      flex flex-shrink-0
      absolute
      m-4
      right-0
      bg-white
      rounded
      shadow-md
      w-auto
    "
	>
		<div
				class="
        flex
        justify-center
        bg-gradient-to-r
        from-primary
        to-primary-600
        mr-4
        px-4
        w-20
        rounded-l
        items-center
      "
		>
			<h1 class="m-0 text-gray-50 text-3xl font-bold">
				{{ getInitials(user.displayName) }}
			</h1>
		</div>
		<div class="flex flex-1 justify-between items-start">
			<div class="m-2">
				<div class="text-container">
					<h5>{{ user.displayName }}</h5>
					<span>{{ user.email }}</span>
				</div>
			</div>
			<div class="flex flex-col px-2 py-2 h-full items-end gap-6">
				<fa-icon
						(click)="isShowingProfileCard = !isShowingProfileCard"
						[icon]="close"
						class="
            cursor-pointer
            hover:text-red-500 hover:bg-gray-100
            rounded-full
            py-1
            px-2
          "
				></fa-icon>
				<app-button
						(click)="onLogout()"
						[isTransparent]="true"
						[label]="'auth.sign-out' | translate"
						[leftIcon]="signOutIcon"
				></app-button>
			</div>
		</div>
	</div>
</div>
