export const environment = {
  production: false,
  staging: true,
  apiEndpoint: 'https://api.staging.services4eo.com/request',
  authority: 'https://triple-a.staging.services4eo.com',
  clientId: '432c2869-56ff-4cc6-8e55-69df4171595c',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '7e0271c8-982d-44fe-ad95-c1d13a3deb34',
  support: 'https://service4eo.atlassian.net/servicedesk',
  EUROPE_REGION_MAP: [35.029996, -20.535156, 51.944265, 35.187500],
  storeUrl: 'https://store.staging.services4eo.com/',
  tutorial: {
    en: 'assets/tutorial/Guide_portail_S4S_V2_EN.pdf',
    fr: 'assets/tutorial/Guide_portail_S4S_V2_FR.pdf'
  }
};
